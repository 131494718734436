import "../../src/App.scss";
import HomePage from "../images/HomePage.svg";
import Logo from "../images/E-Sandesh.svg";
import Introduction from "../images/Introduction.svg";
import TreeType from "../images/TreeType.svg";
import Teacher from "../images/TeachersandStudent.svg";
import MessageSent from "../images/message-sent-81 1.svg";
import Call from "../images/fluent_call-12-filled.svg";
import Mail from "../images/material-symbols-light_mail.svg";
import Copyright from "../images/Copyright.svg";
import Location from "../images/mdi_address-marker.svg";
import GenesisLogo from "../images/Genesis.jpg";
import Link from "../images/Website Link.svg";
import { useNavigate } from "react-router-dom";
const Index = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="main">
        <header className="main__header">
          <div className="main__header--logo">
            <img className="main__header--logo--image" alt="" src={Logo} />
            <h4>Sandesh</h4>
          </div>
          <div className="main__header--login">
            <button onClick={() => (window.location.href ="https://msg.esandesh.org/")}>Login</button>
          </div>
        </header>
        <div className="main__body">
          <div className="main__body--left">
            <img src={HomePage} alt="" className="main__body--left--homepage" />
          </div>
          <div className="main__body--right">
            <div className="main__body--right--block">
              <div className="main__body--right--block--images">
                <img src={Logo} alt="" />
                <h2>Sandesh</h2>
              </div>
              <b className="main__body--right--block--text">
                Your Partner in efficient and effortless communication
              </b>
            </div>
          </div>
        </div>
        <div className="main__second-image">
          <div className="main__second-image--left">
            <img src={Introduction} alt="" />
          </div>
          <div className="main__second-image--details">
            <b>
              Introducing E-Sandesh: The Nexus of Seamless Communication and
              Campus Engagement
            </b>
            <span>
              E-Sandesh serves as the dedicated platform for seamless
              interaction between teachers and students, facilitating efficient
              communication. Additionally, it serves as a conduit for
              institution-wide announcements and campus activities, offering a
              repository of memories for students to revisit and engage with the
              vibrant life within the institution
            </span>
            <img src={TreeType} alt="" />
          </div>
        </div>
        <div className="main__third-image">
          <div className="main__third-image--left">
            <div className="main__third-image--left--block">
              <b>Bridging the Gap Between Teachers and Students</b>
              <span>
                Exclusively tailored for student-teacher communication,
                fostering seamless interaction.
              </span>
            </div>
            <img
              src={MessageSent}
              alt=""
              className="main__third-image--left--image"
            />
          </div>
          <div className="main__third-image--right">
            <img src={Teacher} alt="" />
          </div>
        </div>
        <div className="main__fourth-image"></div>
        <div className="main__address-block">
          <div className="main__address-block--logo">
            <img src={GenesisLogo} alt="" />
          </div>
          <div className="main__address-block--grid">
            <img src={Location} alt="" />
            <span>
              First Floor, #918/2, B.B. Road, Chikkaballapur 562101 Karnataka,
              India (Land Mark : On B.B. Road, Opposite to Railway Station Road,
              Above Bhavani Coffee
            </span>
          </div>
          <div className="main__address-block--grid">
            <img src={Call} alt="" />
            <div>
              <a href="tel:08156-273284">(+91)-08156-273284</a>
              <div className="main__address-block--flex">
              <a href="tel:9845183514">(+91)-9845183514,</a>
              <a href="tel:9945037123">9945037123</a>


              </div>
              <a href="tel:9980923310">9980923310,</a>


              <div className="main__address-block--flex">
                <a href="tel:9611605959">(+91)-9611605959,</a>
                <a href="tel:8310395389">8310395389</a>
              </div>
              <div className="main__address-block--flex">
                <a href="tel:7337866193">(+91)-7337866193,</a>
                <a href="tel:9008383957">9008383957</a>
              </div>
            </div>
          </div>
          <div className="main__address-block--grid">
            <img src={Mail} alt="" />
            <a
              href="mailto:gensofts@gmail.com"
            >
              info@gensofts.com
            </a>
          </div>
          <div className="main__address-block--grid">
            <img src={Link} alt="" />
            <a
              href="https://info@gensofts.com/"
            >
              https://gensofts.com/
            </a>
          </div>
        </div>
        <div className="main__footer">
          <img src={Copyright} alt="" /> Genesis Technologies 2024 |{" "}
          <span onClick={() => navigate(`/privacypolicy`)}>Privacy Policy</span>
        </div>
      </div>
    </>
  );
};

export default Index;
