import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home"
import PrivacyPolicy from './components/PrivacyPolicy';
const App = () => {
  return (
    <div className='h-100'>
        <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />


          </Routes>
          </Router>
      
    </div>
  )
}

export default App
