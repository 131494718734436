import React from "react";
import Logo from "../images/E-Sandesh.svg";
import Previous from "../images/Previous.svg"
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate=useNavigate();
  return (
    <>

      <div className="privacy-policy">
      <header className="main__header">
        <div className="main__header--logo">
          <img className="main__header--logo--image" alt="" src={Logo} />
          <h4>Sandesh</h4>
        </div>
        <div className="main__header--login">
          <button >Login</button>
        </div>
      </header>
    
    <div className="privacy-policy-details">
    <h1>Privacy Policy</h1>
        <h2>Introduction</h2>

        <div className="privacy-policy-contents">
          Genesis Technologies and/or its subsidiary(ies) or its associate(s)
          and/or affiliate(s) (collectively referred to as the "Company” or “We”
          or "E-Sandesh") is committed to protect the privacy of its Customers,
          Partners, Employees or anyone who interacts with us (collectively
          referred to as “User” or “You” or “Your”) at-rest, in-use, and
          in-motion in order to safeguard the business interest and reputation
          without causing any interruption in their day-to-day business
          proceedings. The following are our guiding principles regarding
          privacy:
        </div>
        <ul className="privacy-policy-points">
          <li>
            Ask for only the least amount of information necessary, gathering
            only what we believe is essential for doing business, or for the
            specific transaction at hand.
          </li>
          <li>
            Effective, efficient and sustainable processes for sharing data with
            employees, partners and vendors.
          </li>
          <li>
            Strict approval and authorization mechanism to access the
            information collected.
          </li>
          <li>
            Higher control, visibility and strong technologies in our tools and
            applications with respect to usage and protection of information.
          </li>
          <li>Specific purpose for all information collected.</li>
        </ul>

        <div className="privacy-policy-contents">
          This Privacy Policy explains our policy regarding the collection, use,
          disclosure, transfer or otherwise processing of your data by
          E-Sandesh, which operates various websites, platforms, offline and
          other services including but not limited to delivery of data and
          content via any mobile or internet connected device or otherwise
          (collectively the "Services"). The policy covers the following
          aspects:
        </div>

        <ul className="privacy-policy-points">
          <li>Definitions</li>
          <li>Collection of Data.</li>
          <li>Use of collected information</li>
          <li>Security of data collected</li>
          <li>Disclosures</li>
          <li>Your rights and choices</li>
          <li>General information</li>
          <li>Changes to the privacy policy</li>
          <li>Grievance</li>
          <li>redressal</li>
        </ul>
        <div className="privacy-policy-contents">
          By accessing the Company website or this application (“Platform”) or
          otherwise using the Services, you consent to collection, storage, use,
          disclosure or otherwise processing of the data that you provide
          (including your sensitive personal information) in accordance with
          this Privacy Policy for any of the platform or services that E-Sandesh
          offer. Before sharing any information representing any third party or
          any other person/people, you represent that you have the authority to
          do so and to permit us to use the information in accordance with this
          Privacy Policy. Your personal information will primarily be stored and
          processed in India only.
        </div>
        <h2>Definitions</h2>
        <ul className="privacy-policy-points">
          <li>
            “Customer”, “User”, “You”, “Your” means any individual, entity or
            organization, having entered into any commercial transaction with
            E-Sandesh and/or whose data is being collected by any other means.
          </li>
          <li>
            "Website(s)" means • any website(s) E-Sandesh own and operate such
            as esandesh.org, *.esandesh.org• any web pages or social networks
            that post a link to this privacy policy
          </li>
          <li>
            "E-Sandesh", “We”, “us” means Genesis Technologies. and/or its
            subsidiary(ies) and/or its associate(s), and/or affiliate(s)
          </li>
          <li>
            “Personal Data”, “Data” refers to all the personal, non-anonymized
            data provided by the Customer or any other stakeholder.
          </li>
          <li>
            “Sensitive Personal Data” means such personal information which
            consists of information relating to password, financial information,
            health condition, sexual orientation, medical records, biometric
            information as defined in the Information Technology (Reasonable
            security practices and procedures and sensitive personal data or
            information) Rules, 2011.
          </li>
        </ul>
        <h2>Data Collection</h2>
        <ul className="privacy-policy-list">
          <li>
            <b>1.</b> When you use our Platform, we collect and store your
            information which is provided by you from time to time. In general,
            you may browse the Platform without telling us who you are or
            revealing any personal information about yourself. Once you give us
            your personal information, you are not anonymous to us. You always
            have the option to not provide information by choosing not to use a
            particular service or product provided by us.
          </li>
          <li>
            <b>
              2. E-Sandesh may collect, store and use the following kinds of
              Personal Information such as email address, your physical address,
              name, phone number and mobile number during:
            </b>
            <ul className="privacy-policy-points">
              <li>
                Online and offline data submission, by filling forms online and
                providing name, mobile number, email ID or providing your
                contact information by contacting us through phone, SMS, chat or
                mail
              </li>
              <li>
                Events, trade shows or other marketing activities, the personal
                information that you provide
              </li>
              <li>
                Sharing of billing information such as name, email id, mobile
                number and other information with Genesis Technologies or
                Genesis partners
              </li>
              <li>
                At the time of product usage and activating license, sharing of
                admin ID etc
              </li>
              <li>
                When you send us personal correspondence, such as emails or
                letters
              </li>
              <li>
                We will also collect your information related to your
                transactions on our platform and such third-party business
                partner platforms. When such third-party business partner
                collects your personal information directly from you, you will
                be governed by their privacy policies as well.Genesis Private
                Limited shall not be responsible for the third-party business
                partner’s privacy practices or the content of their privacy
                policies, and we request you to read their privacy policies
                prior to disclosing any information
              </li>
            </ul>
          </li>
          <li>
            <b>3. Cookies</b>
            <br />
            Our Sites use cookies and other technologies to function
            effectively. These technologies record Data about your use of our
            Sites, including: Browser and device data, such as IP address,
            device type, operating system and internet browser type, screen
            resolution, operating system name and version, device manufacturer
            and model, and language/version of the Websites you are visiting;
            Usage data, such as time spent on the Websites, pages visited, links
            clicked, language preferences, and the pages that led or referred
            you to our Websites.
          </li>
        </ul>
        <h2>Use of collected information</h2>
        <ul className="privacy-policy-list">
          <li>
            <b> 1. Advertising and Marketing: </b>
            <br />
            E-Sandesh may use your personal data to advertise and provide
            personalized information about our products and services, on our
            sites and on third party sites. Further to this we may also invite
            you to participate optionally in events and surveys conducted either
            by us or through a third-party market research agency and to follow
            up with requested products and services.
          </li>
          <li>
            <b>2. Customer Support:</b>
            <ul className="privacy-policy-points">
              <li>
                E-Sandesh may use the data collected to identify you and provide
                a personalized support across different touch points, where you
                choose to contact us. To the extent permissible under applicable
                law, we use your information to:
              </li>
              <li>
                Provide any information and services that you have requested or
                any applications or services that you have ordered;
              </li>
              <li>
                Provide, maintain, protect, and improve any applications,
                products, services, and information that you have requested from
                us;
              </li>
              <li>
                Manage and administer your use of applications, products, and
                services you have asked us to provide;
              </li>
              <li>
                Provide you with any information that we are required to send
                you to comply with our regulatory or legal obligations;
              </li>
              <li>
                detect, prevent, investigate, or remediate crime, illegal, or
                prohibited activities or otherwise protect our legal rights
                (including liaison with regulators and law enforcement agencies
                for these purposes);
              </li>
            </ul>
          </li>
        </ul>
        <div className="privacy-policy-contents">
          However, E-Sandesh will not at any time, without your explicit
          consent, supply your personal sensitive data to any third party for
          the purpose of their or any other third party’s direct marketing
          purposes.
        </div>
        <h2>Disclosing Collected Information</h2>
        <div className="privacy-policy-contents">
          E-Sandesh may share your information with:
        </div>
        <ul className="privacy-policy-points">
          <li>
            Any subsidiary or affiliate of E-Sandesh or any other entity/ third-
            party/ body corporate, in India or outside for the purpose in
            accordance with this Policy, as per clause 4 above.
          </li>
          <li>
            Our service providers and agents (including their sub-contractors)
            or third parties which process information on our behalf (e.g.
            internet service and platform providers, payment processing
            providers and those organizations we engage to help us send
            communications to you) so that they may help us to provide you with
            the applications, products, services and information you have
            requested or which we believe is of interest to you;
          </li>
          <li>
            E-Sandesh Partners that may help us to provide you with the
            applications, products, services and information you have requested
            or which we believe is of interest to you;
          </li>
          <li>Third parties used to facilitate payment transactions</li>
          <li>
            Regulators to meet our legal and regulatory obligations and to
            respond to the court orders, or other legal process; and
          </li>
          <li>
            Law enforcement agencies so that they may detect or prevent crime or
            prosecute offenders
          </li>
        </ul>
        <div className="privacy-policy-contents">
          We may share non-personally identifiable information about the use of
          our website, products and/or services publicly or with third parties.
          Under no circumstances, E-Sandesh will publish or share any
          information with any third party for any purpose other than the stated
          ones, without your express consent.
        </div>
        <h2>Security of Data</h2>
        <ul className="privacy-policy-points">
          <li>
            E-Sandesh will take required technical and organizational
            precautions to prevent the loss, misuse or manipulation of the
            information shared. Once your information is in our possession, we
            adhere to our security guidelines to protect it against unauthorized
            access.
          </li>
          <li>
            E-Sandesh will store all the information so collected, on our secure
            password protected servers.
          </li>
          <li>
            You acknowledge the fact that data transmitted through internet may
            be naturally prone to insecurity and E-Sandesh cannot guarantee with
            regard to it.
          </li>
        </ul>
        <div className="privacy-policy-contents">Your Rights and Choices</div>
        <ul className="privacy-policy-points">
          <li>
            <b>Right to Confirmation and Access: </b>Right to know what personal
            data is stored by E-Sandesh, how personal data is being used by
            E-Sandesh
          </li>
          <li>
            <b>Right to Correction and Updation:</b> Right to access, update or
            correct Data residing with E-Sandesh
          </li>
          <li>
            <b>Right to Data Portability:</b> Right to receive your personal
            data in a structured, commonly used and machine-readable format.
            Also, the right to transmit this data to another vendor/company of
            your choice without hindrance from E-Sandesh
          </li>
          <li>
            <b>Right to Be Forgotten or Deletion:</b> Right to erasure or
            restrict or prevent continuing disclosure of Data to E-Sandesh
          </li>
          <li>
            <b>Right to withdrawal of consent:</b> E-Sandesh will provide you
            with an opportunity to exit or withdraw consent from processing your
            data further. however, that withdrawal of consent will not be
            retroactive and will be in accordance with the terms of this Privacy
            Policy, related Terms of Use and applicable laws
          </li>
        </ul>

        <div className="privacy-policy-contents">
          You can write to us at
          <a href="mailto:info@gensofts.com"> info@gensofts.com</a> to assist you with these requests.
        </div>
        <h2>General Information</h2>
        <ul className="privacy-policy-list">
          <li>
            <b> 1. Consent </b>
            <br />
            All collected data is subject to your consent for the collection,
            use, storage, disclosure and otherwise processing of your
            information (including sensitive personal information) in accordance
            with this Privacy Policy. If you disclose to us any personal
            information relating to other people, you represent that you have
            the authority to do so and to permit us to use the information in
            accordance with this Privacy Policy. You can withdraw your consent
            at any time. Withdrawing your consent will not affect the lawfulness
            of any processing E-Sandesh conducted prior to your withdrawal, nor
            will it affect processing of your Data conducted in reliance on
            lawful processing grounds other than consent. However, in the event
            you withdraw consent given to us under this Privacy Policy, such
            withdrawal may hamper your access to the Platform or restrict
            provision of our services to you for which we consider that
            information to be necessary.
          </li>
          <li>
            <b> 2. Retention of Data </b>
            <br />
            We retain your personal information in accordance with appliable
            laws, for a period no longer than is required for the purpose for
            which it was collected or as required under any applicable law.
            However, we may retain data related to you if we believe it may be
            necessary to prevent fraud or future abuse, to enable us to exercise
            its legal rights and/or defend against legal claims or if required
            by law or for other legitimate purposes. We may continue to retain
            your data in anonymised form for analytical and research purposes.
          </li>
          <li>
            <b> 3. Availability of our platform </b>
            <br />
            Use of our Platform is available only to persons who can form a
            legally binding contract under the Indian Contract Act, 1872. We do
            not knowingly solicit or collect personal information from children
            under the age of 18 years. If you have shared any personal
            information of children under the age of 18 years, you represent
            that you have the authority to do so and permit us to use the
            information in accordance with this Privacy Policy.
          </li>
        </ul>
        <h2>Changes to this privacy policy</h2>
        <div className="privacy-policy-contents">
          Please check our Privacy Policy periodically for changes. We may
          update this Privacy Policy to reflect changes to our information
          practices. We will alert you to significant changes by posting the
          date our policy got last updated or placing a notice on our Platform,
          or by sending you an email when we are required to do so under the
          applicable law.
        </div>
        <h2>Grievance Redressal</h2>
        <div className="privacy-policy-contents">
          To exercise any of the rights or for sharing any grievance or queries,
          please write us an email to our Grievance Redressal Officer.
        </div>
        <div className="privacy-policy-contents">
          In accordance with the applicable provisions of the Information
          Technology Act 2000 and rules made there under, the name and contact
          details of the Grievance Redressal Officer are provided below:
        </div>
        <ul>
          <li>
            <b>Name: </b>Praveen
          </li>
          <li>
            <b>Address: </b>
            First Floor, #918/2, B.B. Road, Chikkaballapur 562101 Karnataka,
            India (Land Mark : On B.B. Road, Opposite to Railway Station Road,
            Above Bhavani Coffee)
          </li>
          <li>
            <b>Email: </b>
            <a href="mailto:info@gensofts.com">info@gensofts.com</a>
          </li>
        </ul>

      </div>
      <button className="back-button" onClick={()=>navigate(-1)}><img src={Previous} alt="" />Back</button>


      </div>



    </>
  );
};

export default PrivacyPolicy;
